import React, { createContext, useState, useEffect, useCallback } from 'react';
// import useLocalStorage from './hooks/useLocalStorage';
import useSessionStorage from './hooks/useSessionStorage';
import { api, loggingAPI } from './api' //Lets you set URL from which to call fetching
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import useLocalStorage from 'hooks/useLocalStorage';

// Stores all state variables used in App.js and passed down to children here
// and allows for the use of useContext in other components that would normally have to
// have it passed down as props
export const AppContext = createContext();

export const AppProvider = ({ children }) => {

	// user details 
	const { user } = useAuth0();


	// List of manufacturers
	const manListList = [
		// "Blodgett",
		"Delfield",
		// "Federal Industries",
		"Frymaster",
		// "Hoshizaki",
		// "Jackson",
		"Master-Bilt",
		"Norlake",
		"Pitco",
		"Southbend Range",
		"Structural Concepts",
		"True",
		"Turbo Air",
		"TurboChef",
	];
	const manList = manListList.map(manufacturer => ({ value: manufacturer, label: manufacturer }))
	.sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically by label;


	// NEW SALESFORCE CODE: loading in manufacturer list from backend on app startup =================================================================================================================
	// ===============================================================================================================================================================================================
	// NOTE: TODO: Uncomment when we work with every manufacturer
	/*
	const [manList, setManList] = useLocalStorage("manList", []);
	const [manListLoading, setManListLoading] = useState(false); // Flag on whether the manufacturer list is currently being fetched
	// Fetch manufacturer list from the backend on startup
    useEffect(() => {
        const fetchDisplayNames = async () => {
            try {
				// Prevent another call if data already exists or if a request is in progress
				if (manList.length > 0 || manListLoading) {
					return;
				}
				setManListLoading(true);
                // const response = await axios.get("http://0.0.0.0:8080/api/get-display-names");
				const response = await loggingAPI.get('/api/get-display-names');
                if (response.data.status === "success") {
                    const formattedData = Object.entries(response.data.data).map(([value, label]) => ({ value, label }));
                    setManList(formattedData);  // Set the formatted data as the manufacturer list
                } else {
                    console.error("Failed to load display names:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching display names:", error);
            } finally {
				setManListLoading(false);
			}
        };

        fetchDisplayNames();
    }, []);
	*/
	// ==============================================================================================================================================================================================
	// ==============================================================================================================================================================================================



	// Auto generated ID just to keep track of the session per user
	const [sessionID, setSessionID] = useSessionStorage('sessionID', '');

	// Generate and store session ID when the component mounts
	useEffect(() => {
		const generatesessionID = () => {
			return 'session-' + Math.random().toString(36).substring(2, 17);
		};
		
		const newSessionID = generatesessionID();

		if (sessionID) {
			return;
		}

		setSessionID(newSessionID);
	}, [sessionID, setSessionID]);


	// The ID associated with the current search
	const [searchId, setSearchId] = useState(null);


	// FeedbackPanel states of like/dislike buttons and feedback text for RC, SNL, and web parts
	// Stores what each feedback panel has in terms of like/dislike buttons and feedback text currently
	// Key is mfgPartName + description due to NOT IN WEBSITE having the same ptPartName and mfgPartName for Norlake APIs
	const [feedbackPanelStatesRC, setFeedbackPanelStatesRC] = useState({});
	// Updates the dictionary of feedbackPanelStates with the new feedbackPanelStates
	// useCallback is used to prevent infinite loops from rerendering the function for each FeedbackPanel; only rerenders if the dependencies in the function change
	const handleFeedbackPanelStatesRC = useCallback((mfgPartName, description, { likeClicked, dislikeClicked, feedbackText }) => {
		setFeedbackPanelStatesRC(prevStates => ({
			...prevStates,
			[`${mfgPartName}${description}`]: { likeClicked: likeClicked, dislikeClicked: dislikeClicked, feedbackText: feedbackText }
		}));
	}, [setFeedbackPanelStatesRC]);
	const [feedbackPanelStatesSNL, setFeedbackPanelStatesSNL] = useState({});
	const handleFeedbackPanelStatesSNL = useCallback((mfgPartName, description, { likeClicked, dislikeClicked, feedbackText }) => {
		setFeedbackPanelStatesSNL(prevStates => ({
			...prevStates,
			[`${mfgPartName}${description}`]: { likeClicked: likeClicked, dislikeClicked: dislikeClicked, feedbackText: feedbackText }
		}));
	}, [setFeedbackPanelStatesSNL]);
	const [feedbackPanelStatesWeb, setFeedbackPanelStatesWeb] = useState({});
	const handleFeedbackPanelStatesWeb = useCallback((mfgPartName, description, { likeClicked, dislikeClicked, feedbackText }) => {
		setFeedbackPanelStatesWeb(prevStates => ({
			...prevStates,
			[`${mfgPartName}${description}`]: { likeClicked: likeClicked, dislikeClicked: dislikeClicked, feedbackText: feedbackText }
		}));
	}, [setFeedbackPanelStatesWeb]);


	//Submits the feedback taken from the feedback form
	const logFeedbackHandler = async (typeOfResult, optPartDesc, mfgName, repPartName, ptPartName, mfgPartName, partDesc, imgURL, partObsolete, liked, feedbackText) => {
		const feedbackData = {
			searchID: searchId,
			typeOfResult: typeOfResult,
			optPartDesc: optPartDesc,
			mfgName: mfgName,
			repPartName: repPartName,
			ptPartName: ptPartName,
			mfgPartName: mfgPartName,
			partDesc: partDesc,
			imgURL: imgURL,
			partObsolete: partObsolete,
			liked: liked,
			feedbackText: feedbackText,
		};
		try {
			const response = await loggingAPI.post(`/api/logfeedback`, feedbackData);
			console.log("Feedback logging: ", response.data);
		} catch (error) {
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Error response:', error.response);
			} else if (error.request) {
				// The request was made but no response was received
				console.error('Error request:', error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error message:', error.message);
			}
		}
	};
	//Submits the feedback taken from the feedback form
	const logSearchHandler = async (mfgInput, mnInput, snInput, queryInput) => {
		const searchData = {
			userName: user.preferred_username,
			sessionID: sessionID,
			mfgInput: mfgInput,
			mnInput: mnInput,
			snInput: snInput,
			queryInput: queryInput
		};
		try {
			const source = axios.CancelToken.source();
			const timeout = setTimeout(() => {
				source.cancel();
			}, 2000);
			const response = await loggingAPI.post(`/api/logsearch`, searchData);
			clearTimeout(timeout);
			console.log("Search logging: ", response.data, typeof(response.data.searchid));

			if (typeof response.data.searchid === 'number') {
				const newSearchId = response.data.searchid;
				console.log("DONESEARCHHANDLERBBB", newSearchId);
				return Promise.resolve(newSearchId);
			} else {
				throw new Error('Search ID is not a number');
			}

		} catch (error) {
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Error response:', error.response);
			} else if (error.request) {
				// The request was made but no response was received
				console.error('Error request:', error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error message:', error.message);
			}
			return Promise.reject(-1);
		}
	};

	//Submits the results from an endpoint
	const logResultsHandler = async (newSearchID, newCasesList, newRCResults, newSNLResults, newWebResults, newManResults) => {
		const casesData = {
			searchID: newSearchID,
			casesList: newCasesList,
			rcResults: newRCResults,
			snlResults: newSNLResults,
			webResults: newWebResults,
			manResults: newManResults
		};
		try {
			console.log("Sending out:\n", casesData);
			const response = await loggingAPI.post(`/api/logresults`, casesData);
			console.log("Results logging: ", response.data);
		} catch (error) {
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Error response:', error.response);
			} else if (error.request) {
				// The request was made but no response was received
				console.error('Error request:', error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error message:', error.message);
			}
		}
	};


	//Returned search result manuals, parts, and BF posts based on serial number search
	const [searchResultsManuals, setSearchResultsManuals] = useSessionStorage('searchResultsManuals', {});
	//const [searchResultsParts, setSearchResultsParts] = useSessionStorage('searchResultsParts', {});
	const [searchResultsBF, setSearchResultsBF] = useSessionStorage('searchResultsBF', {});

	//Parts results returned via resolved cases and serial number lookup respectively
	const [searchResultsPartsRC, setSearchResultsPartsRC] = useSessionStorage('searchResultsPartsRC', {});
	// The exact match boolean parameter for the resolved cases result
	const [searchResultsPartsSNL, setSearchResultsPartsSNL] = useSessionStorage('searchResultsPartsSNL', {});

	// NEW: Website data
	const [searchResultsPartsWebsite, setSearchResultsPartsWebsite] = useSessionStorage('searchResultsPartsWebsite', {});

	
	//Whether a call is being fetched or not. The number = # of API calls being fetched at the moment
	const [isLoading, setIsLoading] = useState(0);

	//Stores the keepSRBlank in local storage
	// Whether the app should keep the search results blank (true)
	// or show search results (i.e "No Results" or cards)
	const [keepSRBlank, setKeepSRBlank] = useSessionStorage('keepSRBlank', true);

	//Searches for manuals from serial Numbers
	const snToManuals = async (ser_num, mod_num, manName) => {
		// URL encodes the inputs
		ser_num = encodeURIComponent(ser_num);
		mod_num = encodeURIComponent(mod_num);
		manName = encodeURIComponent(manName);

		try {
			const source = axios.CancelToken.source();
			const timeout = setTimeout(() => {
				source.cancel();
			}, 10000);
			const response = await api.get(`/api/manuals/?ser_num=${ser_num}&mod_num=${mod_num}&mfg_name=${manName}`);
			clearTimeout(timeout);

			return Promise.resolve(response.data.manuals);
			
		} catch (error) {

			var retManData = {};

			if (axios.isCancel(error)) {
				retManData = {"":[]};
			} else {
				retManData = error.response.data.detail.renderData.manuals;
			}

			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Error response:', error.response);
			} else if (error.request) {
				// The request was made but no response was received
				console.error('Error request:', error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error message:', error.message);
			}
			
			// Returns rejected promise to at least signal that the async function ended
			return Promise.reject(retManData);
		} finally {
			console.log("Manual returned")
		}
	};


	//Queries Bloomfire API for serial number, search result, etc.
	const queryToBF = async (query) => {
		try {
			const response = await api.get(`/api/query/bloomfire/${query}`);
			if(response.data.status_code === 200) {
				setSearchResultsBF(response.data.posts);
			} else {
				setSearchResultsBF({});
			};
		} catch (error) {
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Error response:', error.response);
			} else if (error.request) {
				// The request was made but no response was received
				console.error('Error request:', error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error message:', error.message);
			}
		}
	}


	//Searches for parts from serial Numbers via resolved cases
	const snToPartsRC = async (ser_num, mod_num, manName, refine_query) => {
		// URL encodes the inputs
		const enc_ser_num = encodeURIComponent(ser_num);
		const enc_mod_num = encodeURIComponent(mod_num);
		const enc_mfg_name = encodeURIComponent(manName);
		const enc_refine_query = encodeURIComponent(refine_query);

		try {
			const source = axios.CancelToken.source();
			const timeout = setTimeout(() => {
				source.cancel();
			}, 10000);
			const response = await api.get(`/api/partsRes/?ser_num=${enc_ser_num}&mod_num=${enc_mod_num}&mfg_name=${enc_mfg_name}&refine_query=${enc_refine_query}`);
			clearTimeout(timeout);

			// Returns result for logging
			return Promise.resolve(response.data);
		} catch (error) {

			// Value to return in the promise
			var retPartRCData = {};
			
			// Either the formatted error response in renderData
			// or an empty list if the request timed out (since backend then didn't return a response for us to use)
			if (axios.isCancel(error)) {
				retPartRCData = {
					"numberOfCases": 0,
					"casesList": [],
					"parts": {"": []}
				};
			} else {
				retPartRCData = error.response.data.detail.renderData;
			}

			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Error response:', error.response);
			} else if (error.request) {
				// The request was made but no response was received
				console.error('Error request:', error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error message:', error.message);
			}
			
			// Returns empty results for logging
			return Promise.reject(retPartRCData);
		} finally {
			console.log("RC Returned")
		}
	};


	//Searches for parts from serial Numbers via serial number lookup
	const snToPartsSNL = async (ser_num, manName, refine_query) => {
		// URL encodes the inputs
		ser_num = encodeURIComponent(ser_num);
		refine_query = encodeURIComponent(refine_query);
		manName = encodeURIComponent(manName);

		try {
			const source = axios.CancelToken.source();
			const timeout = setTimeout(() => {
				source.cancel();
			}, 10000);
			const response = await api.get(`/api/partsSNL/?ser_num=${ser_num}&mfg_name=${manName}&refine_query=${refine_query}`);
			
			clearTimeout(timeout);
			return Promise.resolve(response.data.parts);
		} catch (error) {
			// Value to return in the promise
			var retPartSNLData = {};
			
			// Either the formatted error response in renderData.parts
			// or an empty list if the request timed out (since backend then didn't return a response for us to use)
			if (axios.isCancel(error)) {
				retPartSNLData = {"": []};
			} else {
				retPartSNLData = error.response.data.detail.renderData.parts;
			}

			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Error response:', error.response);
			} else if (error.request) {
				// The request was made but no response was received
				console.error('Error request:', error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error message:', error.message);
			}

			// Returns an error to end the async function
			// Returns empty results for logging
			return Promise.reject(retPartSNLData);
		} finally {
			console.log("SNL Returned")
		}
	};

	// NEW: Searches for parts from serial Numbers via website data
	const snToPartsWebsite = async (mod_num, manName, refine_query) => {
		// URL encodes the inputs
		mod_num = encodeURIComponent(mod_num);
		manName = encodeURIComponent(manName);
		refine_query = encodeURIComponent(refine_query);
	
		try {
			const source = axios.CancelToken.source();
			const timeout = setTimeout(() => {
				source.cancel();
			}, 10000);
			
	
			// Model number calling to partsWebsite endpoint (implement when Edward done with endpoint)
			const response = await api.get(`/api/partsWeb/?mod_num=${mod_num}&mfg_name=${manName}&refine_query=${refine_query}`);

			clearTimeout(timeout);
			return Promise.resolve(response.data.parts);
		} catch (error) {
			// Value to return in the promise
			var retPartWebsiteData = {};
			
			// Either the formatted error response in renderData.parts
			// or an empty list if the request timed out (since backend then didn't return a response for us to use)
			if (axios.isCancel(error)) {
				retPartWebsiteData = {"": []};
			} else {
				retPartWebsiteData = error.response.data.detail.renderData.parts;
			}
	
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.error('Error response:', error.response);
			} else if (error.request) {
				// The request was made but no response was received
				console.error('Error request:', error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.error('Error message:', error.message);
			}
			
			// Returns an error to end the async function
			// Returns empty results for logging
			return Promise.reject(retPartWebsiteData);
		} finally {
			console.log("Website Returned")
		}
	};




	//Prints number of API calls ongoing/left to go
	useEffect(() => {
		console.log("IsLoading (i.e. current number of API fetches): ", isLoading);
	}, [isLoading]);

	//Prints the results as they arrive
	// useEffect(() => {
	//	 console.log("Search Parts are: ", searchResultsParts);
	// }, [searchResultsParts]);
	useEffect(() => {
		console.log("Search Manuals are: ", searchResultsManuals);
	}, [searchResultsManuals]);
	useEffect(() => {
		console.log("Search Parts Resolved are: ", searchResultsPartsRC);
	}, [searchResultsPartsRC]);
	useEffect(() => {
		console.log("Search Parts SNL are: ", searchResultsPartsSNL);
	}, [searchResultsPartsSNL]);

	// Add useEffect to log Website Lookup results
	useEffect(() => {
		console.log("Search Parts Website are: ", searchResultsPartsWebsite);
	}, [searchResultsPartsWebsite]);

	useEffect(() => {
		console.log("Just performed search: ", searchId);
	}, [searchId]);


	

	return (
		<AppContext.Provider value={{
			manList,
			searchResultsManuals,
			setSearchResultsManuals,
			// searchResultsParts,
			// setSearchResultsParts,
			searchResultsBF,
			setSearchResultsBF,
			isLoading,
			setIsLoading,
			keepSRBlank,
			setKeepSRBlank,
			snToManuals,
			// snToParts,
			queryToBF,
			snToPartsRC,
			logFeedbackHandler,
			logSearchHandler,
			logResultsHandler,
			searchResultsPartsRC,
			setSearchResultsPartsRC,
			searchResultsPartsSNL,
			setSearchResultsPartsSNL,
			snToPartsSNL,
			// WEBSITE ----
			searchResultsPartsWebsite,
			setSearchResultsPartsWebsite,
			snToPartsWebsite,
			// ------------
			setSearchId,
			feedbackPanelStatesRC,
			setFeedbackPanelStatesRC,
			handleFeedbackPanelStatesRC,
			feedbackPanelStatesSNL,
			setFeedbackPanelStatesSNL,
			handleFeedbackPanelStatesSNL,
			feedbackPanelStatesWeb,
			setFeedbackPanelStatesWeb,
			handleFeedbackPanelStatesWeb
		}}>
			{children}
		</AppContext.Provider>
	);
};
